body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.message-container div {
  flex-flow: row;
  -ms-flex-flow: row;
  /* flex-flow: unset; */
}
.message-container > div {
  width: 100%;
}
.quick-replies,
.calendar {
  display: flex;
  justify-content: left;
  text-align: left;
  /* display: table-row; */
}
.quick-replies button,
.calendar .datePickerbtn {
  text-align: center;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  width: 100%;
  height: auto;
  border: 1px solid #661c69 !important;
  border-radius: 4px;
  opacity: 1;
  margin: 0;
  margin-bottom: 4px;
  padding: 8px 10px;
  outline-color: #661c69 !important;
  outline-width: thin !important;
  text-transform: inherit;
}
.calendar .datePickerbtn {
  /* width: 55%; */
  padding: 5px 10px;
}
.quick-replies button:focus,
.calendar .datePickerbtn:focus {
  border: 2.5px solid #661c69;
}

.text-area {
  margin-left: 10px !important;
  width: 95% !important;
  opacity: 1;
}
.text-area::placeholder {
  opacity: 0.5;
}
.text-area:-ms-input-placeholder {
  opacity: 0.5;
}
.text-area::-webkit-input-placeholder {
  opacity: 0.5;
}
textarea:focus {
  outline: 0;
}
.options-btn {
  text-align: left;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  width: 100%;
  height: auto;
  border: 1px solid #661c69;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 4px;
  padding: 8px 10px;
  outline-color: #661c69;
}
.options-btn:focus {
  border: 2.5px solid #661c69;
}
.wrapup-btn {
  width: 100%;
  display: grid;
  justify-content: center;
}

.wrapup-btn button {
  text-align: center;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  width: 212px;
  height: 45px;
  border: 1px solid #661c69 !important;
  border-radius: 4px;
  opacity: 1;
  padding: 8px 10px;
  outline-color: #661c69 !important;
  text-transform: inherit;
}
.form-btn button {
  text-align: center;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  width: 100%;
  height: 38px;
  border: 1px solid #661c69 !important;
  border-radius: 4px;
  opacity: 1;
  padding: 8px 10px;
  outline-color: #661c69 !important;
  text-transform: inherit;
}
.wrapup-btn button:focus {
  border: 2.5px solid #661c69;
}
.wrapup-btn button.end-btn {
  background: #661c69;
  color: #fff;
  margin-top: 20px;
}
.form-btn button.end-btn {
  background: #661c69;
  color: #fff;
  margin-top: 3px;
}
.wrapup-btn button.end-btn:focus,
.form-btn button.end-btn:focus {
  border: 2.5px solid #000;
}
button.cancel-process-btn {
  font: normal normal 14px/17px Arial, sans-serif;
  background: #661c69;
  color: #fff;
  margin: 3px 0 0 15%;
  border-radius: 4px;
  width: 79.5%;
}
@media (max-width: 1200px) {
  button.cancel-process-btn {
    margin: 3px 0 0 16%;
    width: 82%;
  }
}
button.cancel-process-btn:focus {
  border: 2.5px solid #000;
}
.wrapupone-btn button {
  text-align: center;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  width: 112px;
  height: 45px;
  border: 1px solid #661c69;
  border-radius: 4px;
  opacity: 1;
  padding: 8px 10px;
  outline-color: #661c69;
}
.quick-replies button:focus,
.account-btn button:focus {
  border: 2.5px solid #661c69;
}
.quick-replies button.end-btn {
  background: #661c69;
  color: #fff;
}
.account-btn button {
  width: 100%;
  display: block;
  text-align: center;
  background: #fff;
  color: #661c69;
  font: normal normal bold 14px/17px Arial, sans-serif;
  font-weight: bold;
  border: 1px solid #661c69;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 4px;
  padding: 8px 10px;
  outline-color: #661c69;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: #f5821f;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 3px;
}
.svg-custom g {
  stroke: #fff;
}
.bot-message {
  padding: 10px 13px 9px 10px !important;
}
.user-message {
  padding: 10px 12px 9px 15px !important;
}

.main-wrapper {
  display: flex !important;
  width: 429px !important;
  height: 95vh !important;
  margin-bottom: 10px;
  border-radius: 20px;
  box-shadow: none;
}
.main-wrapper-minimize {
  width: 107px !important;
  height: 67px !important;
}
.main-container {
  display: flex;
  flex-direction: inherit;
  width: 100%;
  height: 95vh;
  border-radius: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(70, 70, 70, 0.27);
  opacity: 1;
  place-self: flex-end;
}
.main-container > div {
  align-items: center;
}
.leftIcons + div {
  width: 90%;
}
.main-container .titleHeading {
  font-size: 24px !important;
  margin-bottom: -4px;
  padding-left: 35px;
  margin-top: 0;
  color: #333;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.main-container .endchatHeading {
  font-size: 28px !important;
  margin-bottom: 0;
  line-height: 44px;
  color: #333;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.agentWrapupContainer {
  /* width: 80%; */
  height: 80px;
  display: inline-flex;
  margin-top: 10px;
}
.smileyImg {
  width: 100%;
  height: 100%;
}
.messageContainerWrap {
  background: #fff;
  padding: 0 !important;
  height: 95% !important;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
@media screen and (max-width: 650px) {
  .agentWrapupContainer {
    /* width: 75%; */
    height: 65px;
  }
  .smileyImg {
    width: 41px;
    height: 41px;
  }
}
@media screen and (max-height: 750px) {
  .messageContainerWrap {
    display: block !important;
    margin: 0 !important;
  }
}
.messageContainer {
  background: #fff;
  padding: 0 !important;
  height: 98% !important;
}
.languageLink {
  font-size: 16px;
  font-weight: bold;
  color: #661c69;
  border-bottom: 2px solid rgb(102, 28, 105);
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  height: 19px;
  cursor: pointer;
  width: 34.02px;
  margin-left: 3px;
}
.checkbox-label .checkbox-custom {
  display: inline-block;
  margin: 0 12px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  border: 2px solid rgba(0, 0, 0, 0.54);
}
.checkbox-label input:focus ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2.2px solid #661c69;
}
.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgba(0, 0, 0, 0.54);
}
.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}
.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 10px;
  top: -15px;
  width: 9px;
  height: 25px;
  border: solid #f5821f;
  border-width: 0 2.5px 2.5px 0;
  background-color: transparent;
  border-radius: 0;
}
/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.checkbox-label-text {
  color: #661c69;
  vertical-align: top;
  font-family: Arial, sans-serif;
  font-size: 15px;
}
.email-field,
.email-field2 {
  padding: 10px;
  margin: 10px 0 0 0;
  border: 1.5px solid #661c69;
  border-radius: 10px;
  width: 190px;
  font-size: 14px;
}
.email-field:focus,
.email-field2:focus {
  outline: none;
}
/* .calendar .MuiPickersBasePicker-container {
  flex-direction: column !important;
  width: 100%;
} */
.calendar .MuiPickersDatePickerRoot-toolbar {
  align-items: flex-start !important;
  flex-direction: column !important;
}
.calendar .MuiPickersBasePicker-pickerView {
  flex-direction: column !important;
}
.custom-textarea .MuiOutlinedInput-notchedOutline {
  border: none;
}
.custom-textarea .MuiOutlinedInput-input{
  padding: 10px 0 13.5px 2.5px;
}
.checkbox-label {
  cursor: pointer;
  /* display: inline-block; */
}
@media (max-width: 500px) {
  .main-wrapper {
    width: 99vw !important;
    height: 90vh !important;
  }
  .main-container {
    width: 99vw;
    height: 90vh;
  }
  .main-wrapper.device-iphone {
    width: 98% !important;
    height: 99% !important;
    margin-bottom: 3px;
    margin-right: 5px;
  }
  .device-iphone .main-container {
    width: 98%;
    height: 99%;
  }
}
@media (max-width: 320px) {
  .bubble-container {
    width: 86% !important;
  }
  .datePickerbtn.endDate-btn {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  /* .main-wrapper {
      width: 57vh !important;
  } */
  /* .main-container {
          width: 100%;
  } */
}
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 12px;
}
.custom-connector {
  width: 100%;
  height: 1px;
  background: #6d6e70;
  position: absolute;
  top: 20px;
}

@media screen and (max-width: 650px) {
  .custom-connector {
    top: 17px;
  }
}

/* .MuiStepLabel-label.MuiStepLabel-completed>div.custom-connector {
  background: #661C69 !important;
}

.MuiStepLabel-label.MuiStepLabel-active>div.custom-connector {
  background: #661C69 !important;
} */
.MuiStepConnector-lineHorizontal {
  display: none !important;
}
.modal_container .MuiStep-horizontal {
  padding: 0;
}

.dob-select fieldset.MuiOutlinedInput-notchedOutline {
  background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1;
  border-left: 0;
  border-radius: 0;
}

.dob-select-day fieldset.MuiOutlinedInput-notchedOutline {
  border-radius: 3px 0 0 3px;
  background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1;
}
.dob-select-datepicker {
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box !important; */
  background: rgba(85, 88, 88, 0.04) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-radius: 0 3px 3px 0;
  border-left: 0;
  opacity: 1;
}
.dob-select-datepicker .MuiGrid-item {
  margin-right: 14px;
}
#customerCheckIn .MuiFormLabel-asterisk {
  color: red;
}
@media screen and (max-width: 650px) {
  .dob-select-datepicker .MuiGrid-item {
    margin-right: 8px;
  }
}
.modal_container .MuiDialog-paperWidthSm {
  max-width: 95%;
  max-height: 95%;
  width: 95%;
  height: 95%;
  min-height: 97vh;
  min-width: 97vw;
}
@media (min-width: 1400px) and (max-width: 1649px) {
  .modal_container .MuiDialog-paperWidthSm {
    max-width: 80%;
    max-height: 95%;
    width: 80%;
    height: 95%;
    min-height: 95vh;
    min-width: 80vw;
  }
}
@media screen and (min-width: 1650px) {
  .modal_container .MuiDialog-paperWidthSm {
    max-width: 65%;
    max-height: 88%;
    width: 65%;
    height: 88%;
    min-height: 88vh;
    min-width: 65vw;
  }
}
@media screen and (max-width: 500px) {
  .modal_container .MuiDialog-paperWidthSm {
    max-width: 98%;
    max-height: 87%;
    width: 98%;
    height: 87%;
    min-height: 87vh;
    min-width: 98vw;
  }
}
.start-step-0 {
  width: 4px;
  height: 4px;
  position: absolute;
  border: 1px solid #707070;
  border-radius: 50%;
  top: 18px;
  left: -6px;
}
.end-step-2 {
  width: 4px;
  height: 4px;
  position: absolute;
  border: 1px solid #707070;
  border-radius: 50%;
  top: 18px;
  right: -6px;
}
.personal_mo_number fieldset.MuiOutlinedInput-notchedOutline {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  display: none;
}
.datePickerbtn .a{
  fill:#fff;
}
.datePickerbtn .a, .datePickerbtn .b{
  stroke:#661c69;
}
.datePickerbtn .b,.datePickerbtn .d{
  fill:none;
}
.datePickerbtn .c{
  stroke:none;
}
@media screen and (max-width: 650px) {
  .start-step-0 {
    top: 15px;
  }
  .end-step-2 {
    top: 15px;
  }
}

.progressBar {
  position: relative;
  height: 15px;
  background-color: #f87605;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  width: 79.5%;
  font: normal normal 12px/17px Arial, sans-serif;
}
@media (max-width: 1200px) {
  .progressBar {
    width: 82%;
  }
}
.progressBar .progressBar-color {
  position: absolute;
  background-color: #f5821f;
  width: 0px;
  height: 12px;
  z-index: 99;
  /* margin-top: -15px; */
  border-radius: 15px;
  animation: progres 3s infinite linear;
}
@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}
